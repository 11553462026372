



































import { formatIsoDateString, formatIsoTimeString } from "@/helpers";
import { Locale } from "@/spect8-core-vue/src/i18n";
import { VForm } from "@/types";
import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop, Ref, Watch } from "vue-property-decorator";

@Component({})
export default class DateTimePicker extends Vue {
  @Model("change", { type: Number }) dateTime: number | null | undefined;
  @Prop() maxDate: number | null | undefined;
  @Prop() minDate: number | null | undefined;
  @Prop({ default: false }) required!: boolean;

  @Ref("time") timeTextField!: VForm;

  date: string | null = null;
  time: string | null = null;

  maxDateIso: string | null = null;
  minDateIso: string | null = null;

  menuDate = false;

  rules = {
    required: (value: string) =>
      !!value || this.$i18n.t("formErrors.required").toString(),
    time: (value: string) => {
      const pattern =
        this.$i18n.locale === Locale.EN
          ? /^ *(1[0-2]|0[0-9]):[0-5][0-9] *(a|p|A|P)(m|M) *$/
          : /^(?:[01]\d|2[0-3]):[0-5]\d$/;
      return (
        value === null ||
        pattern.test(value) ||
        this.$i18n.t("formErrors.validateTimeFormat").toString()
      );
    },
  };

  @Watch("dateTime", { immediate: true })
  onDateTimeChanged(): void {
    if (this.dateTime) {
      const dateTime = new Date(this.dateTime);
      this.date = formatIsoDateString(dateTime);
      this.time = formatIsoTimeString(dateTime);
    } else {
      this.date = null;
      this.time = null;
      if (this.timeTextField) {
        this.timeTextField.reset();
      }
    }
  }

  @Watch("maxDate", { immediate: true })
  onMaxDateChanged(): void {
    if (this.maxDate) {
      this.maxDateIso = formatIsoDateString(new Date(this.maxDate));
    } else {
      this.maxDateIso = null;
    }
  }

  @Watch("minDate", { immediate: true })
  onMinDateChanged(): void {
    if (this.minDate) {
      this.minDateIso = formatIsoDateString(new Date(this.minDate));
    } else {
      this.minDateIso = null;
    }
  }

  @Watch("$i18n.locale")
  onLocaleChanged(newValue: string, oldValue: string): void {
    if (newValue !== oldValue && this.dateTime) {
      this.time = formatIsoTimeString(new Date(this.dateTime));
    }
  }

  formatDate(): string {
    if (this.dateTime) {
      return this.$d(this.dateTime, "DateShort");
    }
    return "";
  }

  handleDateChange(): void {
    this.updateDateTime();
    this.menuDate = false;
  }

  handleTimeChange(): void {
    this.updateDateTime();
  }

  parseTime(time: string | null) {
    if (time) {
      if (time.slice(-2) === "am") {
        return time.slice(0, -2);
      } else {
        time = time.slice(0, -2);
        return `${parseInt(time.split(":")[0]) + 12}:${time.split(":")[1]}`;
      }
    }
    return null;
  }

  updateDateTime(): void {
    if (this.date) {
      let updatedDateTime;
      if (this.$i18n.locale === Locale.EN) {
        const parsedTime = this.parseTime(this.time);
        if (parsedTime) {
          updatedDateTime = this.time
            ? `${this.date}T${parsedTime}`
            : this.date;
        } else {
          updatedDateTime = this.date;
        }
      } else {
        updatedDateTime = this.time ? `${this.date}T${this.time}` : this.date;
      }
      this.$emit("change", new Date(updatedDateTime).getTime());
    }
  }
}
